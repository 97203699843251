// source: shoot.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.AssignBotRequest', null, global);
goog.exportSymbol('proto.Bid', null, global);
goog.exportSymbol('proto.BidList', null, global);
goog.exportSymbol('proto.BidRequest', null, global);
goog.exportSymbol('proto.Card', null, global);
goog.exportSymbol('proto.Card.Rank', null, global);
goog.exportSymbol('proto.Card.Suit', null, global);
goog.exportSymbol('proto.CreateGameRequest', null, global);
goog.exportSymbol('proto.CreateGameResponse', null, global);
goog.exportSymbol('proto.EndGame', null, global);
goog.exportSymbol('proto.Hand', null, global);
goog.exportSymbol('proto.JoinGameRequest', null, global);
goog.exportSymbol('proto.JoinGameResponse', null, global);
goog.exportSymbol('proto.LeaveGameRequest', null, global);
goog.exportSymbol('proto.Notification', null, global);
goog.exportSymbol('proto.Notification.NotificationCase', null, global);
goog.exportSymbol('proto.PlayCardRequest', null, global);
goog.exportSymbol('proto.PlayedCard', null, global);
goog.exportSymbol('proto.PlayedCards', null, global);
goog.exportSymbol('proto.Scores', null, global);
goog.exportSymbol('proto.SeatDetails', null, global);
goog.exportSymbol('proto.SeatsList', null, global);
goog.exportSymbol('proto.SetReadyStatusRequest', null, global);
goog.exportSymbol('proto.StartGame', null, global);
goog.exportSymbol('proto.StatusResponse', null, global);
goog.exportSymbol('proto.TakeSeatRequest', null, global);
goog.exportSymbol('proto.ThrowawayRequest', null, global);
goog.exportSymbol('proto.ThrowawayResponse', null, global);
goog.exportSymbol('proto.Transfer', null, global);
goog.exportSymbol('proto.TransferComplete', null, global);
goog.exportSymbol('proto.TransferRequest', null, global);
goog.exportSymbol('proto.Tricks', null, global);
goog.exportSymbol('proto.Trump', null, global);
goog.exportSymbol('proto.TrumpUpdate', null, global);
goog.exportSymbol('proto.UpdateTimeout', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Card = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Card, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Card.displayName = 'proto.Card';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StatusResponse.displayName = 'proto.StatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SeatDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SeatDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SeatDetails.displayName = 'proto.SeatDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SeatsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SeatsList.repeatedFields_, null);
};
goog.inherits(proto.SeatsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SeatsList.displayName = 'proto.SeatsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateGameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateGameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateGameRequest.displayName = 'proto.CreateGameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateGameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateGameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateGameResponse.displayName = 'proto.CreateGameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.JoinGameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.JoinGameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.JoinGameRequest.displayName = 'proto.JoinGameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.JoinGameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.JoinGameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.JoinGameResponse.displayName = 'proto.JoinGameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetReadyStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SetReadyStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetReadyStatusRequest.displayName = 'proto.SetReadyStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StartGame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StartGame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StartGame.displayName = 'proto.StartGame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Hand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Hand.repeatedFields_, null);
};
goog.inherits(proto.Hand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Hand.displayName = 'proto.Hand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BidRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BidRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BidRequest.displayName = 'proto.BidRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Bid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Bid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Bid.displayName = 'proto.Bid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BidList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.BidList.repeatedFields_, null);
};
goog.inherits(proto.BidList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BidList.displayName = 'proto.BidList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TakeSeatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TakeSeatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TakeSeatRequest.displayName = 'proto.TakeSeatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AssignBotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AssignBotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AssignBotRequest.displayName = 'proto.AssignBotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TransferRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TransferRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TransferRequest.displayName = 'proto.TransferRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Transfer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Transfer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Transfer.displayName = 'proto.Transfer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TransferComplete = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TransferComplete, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TransferComplete.displayName = 'proto.TransferComplete';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ThrowawayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ThrowawayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ThrowawayRequest.displayName = 'proto.ThrowawayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ThrowawayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ThrowawayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ThrowawayResponse.displayName = 'proto.ThrowawayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrumpUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TrumpUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrumpUpdate.displayName = 'proto.TrumpUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PlayCardRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PlayCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PlayCardRequest.displayName = 'proto.PlayCardRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateTimeout = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateTimeout, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateTimeout.displayName = 'proto.UpdateTimeout';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PlayedCard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PlayedCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PlayedCard.displayName = 'proto.PlayedCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PlayedCards = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PlayedCards.repeatedFields_, null);
};
goog.inherits(proto.PlayedCards, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PlayedCards.displayName = 'proto.PlayedCards';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Tricks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Tricks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Tricks.displayName = 'proto.Tricks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Scores = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Scores, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Scores.displayName = 'proto.Scores';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EndGame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EndGame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EndGame.displayName = 'proto.EndGame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LeaveGameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LeaveGameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LeaveGameRequest.displayName = 'proto.LeaveGameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Notification.oneofGroups_);
};
goog.inherits(proto.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Notification.displayName = 'proto.Notification';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Card.prototype.toObject = function(opt_includeInstance) {
  return proto.Card.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Card} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Card.toObject = function(includeInstance, msg) {
  var f, obj = {
    suit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rank: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Card}
 */
proto.Card.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Card;
  return proto.Card.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Card} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Card}
 */
proto.Card.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Card.Suit} */ (reader.readEnum());
      msg.setSuit(value);
      break;
    case 2:
      var value = /** @type {!proto.Card.Rank} */ (reader.readEnum());
      msg.setRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Card.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Card.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Card} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Card.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuit();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRank();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.Card.Suit = {
  SPADES: 0,
  HEARTS: 1,
  DIAMONDS: 2,
  CLUBS: 3
};

/**
 * @enum {number}
 */
proto.Card.Rank = {
  SEVEN: 0,
  EIGHT: 1,
  NINE: 2,
  TEN: 3,
  JACK: 4,
  QUEEN: 5,
  KING: 6,
  ACE: 7
};

/**
 * optional Suit suit = 1;
 * @return {!proto.Card.Suit}
 */
proto.Card.prototype.getSuit = function() {
  return /** @type {!proto.Card.Suit} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.Card.Suit} value
 * @return {!proto.Card} returns this
 */
proto.Card.prototype.setSuit = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Rank rank = 2;
 * @return {!proto.Card.Rank}
 */
proto.Card.prototype.getRank = function() {
  return /** @type {!proto.Card.Rank} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.Card.Rank} value
 * @return {!proto.Card} returns this
 */
proto.Card.prototype.setRank = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.StatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorNum: jspb.Message.getFieldWithDefault(msg, 2, 0),
    errorText: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StatusResponse}
 */
proto.StatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StatusResponse;
  return proto.StatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StatusResponse}
 */
proto.StatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorNum(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorNum();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getErrorText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.StatusResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.StatusResponse} returns this
 */
proto.StatusResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 error_num = 2;
 * @return {number}
 */
proto.StatusResponse.prototype.getErrorNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatusResponse} returns this
 */
proto.StatusResponse.prototype.setErrorNum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string error_text = 3;
 * @return {string}
 */
proto.StatusResponse.prototype.getErrorText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.StatusResponse} returns this
 */
proto.StatusResponse.prototype.setErrorText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SeatDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.SeatDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SeatDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SeatDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    seat: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ready: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    empty: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    human: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    name: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SeatDetails}
 */
proto.SeatDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SeatDetails;
  return proto.SeatDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SeatDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SeatDetails}
 */
proto.SeatDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeat(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReady(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmpty(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHuman(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SeatDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SeatDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SeatDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SeatDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeat();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getReady();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getEmpty();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHuman();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 seat = 1;
 * @return {number}
 */
proto.SeatDetails.prototype.getSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SeatDetails} returns this
 */
proto.SeatDetails.prototype.setSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool ready = 2;
 * @return {boolean}
 */
proto.SeatDetails.prototype.getReady = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SeatDetails} returns this
 */
proto.SeatDetails.prototype.setReady = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool empty = 3;
 * @return {boolean}
 */
proto.SeatDetails.prototype.getEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SeatDetails} returns this
 */
proto.SeatDetails.prototype.setEmpty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool human = 4;
 * @return {boolean}
 */
proto.SeatDetails.prototype.getHuman = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SeatDetails} returns this
 */
proto.SeatDetails.prototype.setHuman = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.SeatDetails.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.SeatDetails} returns this
 */
proto.SeatDetails.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SeatsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SeatsList.prototype.toObject = function(opt_includeInstance) {
  return proto.SeatsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SeatsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SeatsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    proto.SeatDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SeatsList}
 */
proto.SeatsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SeatsList;
  return proto.SeatsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SeatsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SeatsList}
 */
proto.SeatsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SeatDetails;
      reader.readMessage(value,proto.SeatDetails.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SeatsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SeatsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SeatsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SeatsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SeatDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SeatDetails seats = 1;
 * @return {!Array<!proto.SeatDetails>}
 */
proto.SeatsList.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.SeatDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SeatDetails, 1));
};


/**
 * @param {!Array<!proto.SeatDetails>} value
 * @return {!proto.SeatsList} returns this
*/
proto.SeatsList.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SeatDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SeatDetails}
 */
proto.SeatsList.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SeatDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SeatsList} returns this
 */
proto.SeatsList.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateGameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateGameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateGameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seats: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateGameRequest}
 */
proto.CreateGameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateGameRequest;
  return proto.CreateGameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateGameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateGameRequest}
 */
proto.CreateGameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateGameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateGameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateGameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeats();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 seats = 1;
 * @return {number}
 */
proto.CreateGameRequest.prototype.getSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.CreateGameRequest} returns this
 */
proto.CreateGameRequest.prototype.setSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateGameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateGameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateGameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateGameResponse}
 */
proto.CreateGameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateGameResponse;
  return proto.CreateGameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateGameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateGameResponse}
 */
proto.CreateGameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateGameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateGameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateGameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateGameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.CreateGameResponse.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateGameResponse} returns this
 */
proto.CreateGameResponse.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.JoinGameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.JoinGameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.JoinGameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.JoinGameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.JoinGameRequest}
 */
proto.JoinGameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.JoinGameRequest;
  return proto.JoinGameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.JoinGameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.JoinGameRequest}
 */
proto.JoinGameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.JoinGameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.JoinGameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.JoinGameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.JoinGameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.JoinGameRequest.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.JoinGameRequest} returns this
 */
proto.JoinGameRequest.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.JoinGameRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.JoinGameRequest} returns this
 */
proto.JoinGameRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.JoinGameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.JoinGameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.JoinGameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.JoinGameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seats: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.JoinGameResponse}
 */
proto.JoinGameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.JoinGameResponse;
  return proto.JoinGameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.JoinGameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.JoinGameResponse}
 */
proto.JoinGameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.JoinGameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.JoinGameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.JoinGameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.JoinGameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeats();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.JoinGameResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.JoinGameResponse} returns this
 */
proto.JoinGameResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 seats = 2;
 * @return {number}
 */
proto.JoinGameResponse.prototype.getSeats = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.JoinGameResponse} returns this
 */
proto.JoinGameResponse.prototype.setSeats = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetReadyStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SetReadyStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetReadyStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetReadyStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ready: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetReadyStatusRequest}
 */
proto.SetReadyStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetReadyStatusRequest;
  return proto.SetReadyStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetReadyStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetReadyStatusRequest}
 */
proto.SetReadyStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReady(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetReadyStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetReadyStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetReadyStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetReadyStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReady();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool ready = 1;
 * @return {boolean}
 */
proto.SetReadyStatusRequest.prototype.getReady = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SetReadyStatusRequest} returns this
 */
proto.SetReadyStatusRequest.prototype.setReady = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StartGame.prototype.toObject = function(opt_includeInstance) {
  return proto.StartGame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StartGame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StartGame.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StartGame}
 */
proto.StartGame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StartGame;
  return proto.StartGame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StartGame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StartGame}
 */
proto.StartGame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StartGame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StartGame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StartGame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StartGame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Hand.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Hand.prototype.toObject = function(opt_includeInstance) {
  return proto.Hand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Hand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Hand.toObject = function(includeInstance, msg) {
  var f, obj = {
    handList: jspb.Message.toObjectList(msg.getHandList(),
    proto.Card.toObject, includeInstance),
    dealer: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Hand}
 */
proto.Hand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Hand;
  return proto.Hand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Hand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Hand}
 */
proto.Hand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Card;
      reader.readMessage(value,proto.Card.deserializeBinaryFromReader);
      msg.addHand(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Hand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Hand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Hand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Hand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHandList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Card.serializeBinaryToWriter
    );
  }
  f = message.getDealer();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated Card hand = 1;
 * @return {!Array<!proto.Card>}
 */
proto.Hand.prototype.getHandList = function() {
  return /** @type{!Array<!proto.Card>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Card, 1));
};


/**
 * @param {!Array<!proto.Card>} value
 * @return {!proto.Hand} returns this
*/
proto.Hand.prototype.setHandList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Card=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Card}
 */
proto.Hand.prototype.addHand = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Card, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Hand} returns this
 */
proto.Hand.prototype.clearHandList = function() {
  return this.setHandList([]);
};


/**
 * optional uint32 dealer = 2;
 * @return {number}
 */
proto.Hand.prototype.getDealer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Hand} returns this
 */
proto.Hand.prototype.setDealer = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BidRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.BidRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BidRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BidRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BidRequest}
 */
proto.BidRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BidRequest;
  return proto.BidRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BidRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BidRequest}
 */
proto.BidRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BidRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BidRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BidRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BidRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Bid.prototype.toObject = function(opt_includeInstance) {
  return proto.Bid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Bid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Bid.toObject = function(includeInstance, msg) {
  var f, obj = {
    tricks: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shootNum: jspb.Message.getFieldWithDefault(msg, 2, 0),
    trump: jspb.Message.getFieldWithDefault(msg, 3, 0),
    seat: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Bid}
 */
proto.Bid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Bid;
  return proto.Bid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Bid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Bid}
 */
proto.Bid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTricks(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShootNum(value);
      break;
    case 3:
      var value = /** @type {!proto.Trump} */ (reader.readEnum());
      msg.setTrump(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Bid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Bid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Bid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Bid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTricks();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getShootNum();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTrump();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSeat();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 tricks = 1;
 * @return {number}
 */
proto.Bid.prototype.getTricks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Bid} returns this
 */
proto.Bid.prototype.setTricks = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 shoot_num = 2;
 * @return {number}
 */
proto.Bid.prototype.getShootNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Bid} returns this
 */
proto.Bid.prototype.setShootNum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Trump trump = 3;
 * @return {!proto.Trump}
 */
proto.Bid.prototype.getTrump = function() {
  return /** @type {!proto.Trump} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Trump} value
 * @return {!proto.Bid} returns this
 */
proto.Bid.prototype.setTrump = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 seat = 4;
 * @return {number}
 */
proto.Bid.prototype.getSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Bid} returns this
 */
proto.Bid.prototype.setSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BidList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BidList.prototype.toObject = function(opt_includeInstance) {
  return proto.BidList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BidList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BidList.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidsList: jspb.Message.toObjectList(msg.getBidsList(),
    proto.Bid.toObject, includeInstance),
    currentBidder: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BidList}
 */
proto.BidList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BidList;
  return proto.BidList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BidList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BidList}
 */
proto.BidList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Bid;
      reader.readMessage(value,proto.Bid.deserializeBinaryFromReader);
      msg.addBids(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentBidder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BidList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BidList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BidList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BidList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Bid.serializeBinaryToWriter
    );
  }
  f = message.getCurrentBidder();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated Bid bids = 1;
 * @return {!Array<!proto.Bid>}
 */
proto.BidList.prototype.getBidsList = function() {
  return /** @type{!Array<!proto.Bid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Bid, 1));
};


/**
 * @param {!Array<!proto.Bid>} value
 * @return {!proto.BidList} returns this
*/
proto.BidList.prototype.setBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Bid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Bid}
 */
proto.BidList.prototype.addBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Bid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BidList} returns this
 */
proto.BidList.prototype.clearBidsList = function() {
  return this.setBidsList([]);
};


/**
 * optional uint32 current_bidder = 2;
 * @return {number}
 */
proto.BidList.prototype.getCurrentBidder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.BidList} returns this
 */
proto.BidList.prototype.setCurrentBidder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TakeSeatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TakeSeatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TakeSeatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TakeSeatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seat: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TakeSeatRequest}
 */
proto.TakeSeatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TakeSeatRequest;
  return proto.TakeSeatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TakeSeatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TakeSeatRequest}
 */
proto.TakeSeatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TakeSeatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TakeSeatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TakeSeatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TakeSeatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeat();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 seat = 1;
 * @return {number}
 */
proto.TakeSeatRequest.prototype.getSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TakeSeatRequest} returns this
 */
proto.TakeSeatRequest.prototype.setSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AssignBotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AssignBotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AssignBotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AssignBotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seat: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AssignBotRequest}
 */
proto.AssignBotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AssignBotRequest;
  return proto.AssignBotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AssignBotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AssignBotRequest}
 */
proto.AssignBotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AssignBotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AssignBotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AssignBotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AssignBotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeat();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 seat = 1;
 * @return {number}
 */
proto.AssignBotRequest.prototype.getSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.AssignBotRequest} returns this
 */
proto.AssignBotRequest.prototype.setSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TransferRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TransferRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TransferRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TransferRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromSeat: jspb.Message.getFieldWithDefault(msg, 1, 0),
    toSeat: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TransferRequest}
 */
proto.TransferRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TransferRequest;
  return proto.TransferRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TransferRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TransferRequest}
 */
proto.TransferRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFromSeat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setToSeat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TransferRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TransferRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TransferRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TransferRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromSeat();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getToSeat();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 from_seat = 1;
 * @return {number}
 */
proto.TransferRequest.prototype.getFromSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TransferRequest} returns this
 */
proto.TransferRequest.prototype.setFromSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 to_seat = 2;
 * @return {number}
 */
proto.TransferRequest.prototype.getToSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TransferRequest} returns this
 */
proto.TransferRequest.prototype.setToSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Transfer.prototype.toObject = function(opt_includeInstance) {
  return proto.Transfer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Transfer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transfer.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromSeat: jspb.Message.getFieldWithDefault(msg, 1, 0),
    toSeat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    card: (f = msg.getCard()) && proto.Card.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Transfer}
 */
proto.Transfer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Transfer;
  return proto.Transfer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Transfer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Transfer}
 */
proto.Transfer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFromSeat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setToSeat(value);
      break;
    case 3:
      var value = new proto.Card;
      reader.readMessage(value,proto.Card.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Transfer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Transfer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Transfer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Transfer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromSeat();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getToSeat();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 from_seat = 1;
 * @return {number}
 */
proto.Transfer.prototype.getFromSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Transfer} returns this
 */
proto.Transfer.prototype.setFromSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 to_seat = 2;
 * @return {number}
 */
proto.Transfer.prototype.getToSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Transfer} returns this
 */
proto.Transfer.prototype.setToSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Card card = 3;
 * @return {?proto.Card}
 */
proto.Transfer.prototype.getCard = function() {
  return /** @type{?proto.Card} */ (
    jspb.Message.getWrapperField(this, proto.Card, 3));
};


/**
 * @param {?proto.Card|undefined} value
 * @return {!proto.Transfer} returns this
*/
proto.Transfer.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Transfer} returns this
 */
proto.Transfer.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Transfer.prototype.hasCard = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TransferComplete.prototype.toObject = function(opt_includeInstance) {
  return proto.TransferComplete.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TransferComplete} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TransferComplete.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromSeat: jspb.Message.getFieldWithDefault(msg, 1, 0),
    toSeat: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TransferComplete}
 */
proto.TransferComplete.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TransferComplete;
  return proto.TransferComplete.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TransferComplete} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TransferComplete}
 */
proto.TransferComplete.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFromSeat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setToSeat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TransferComplete.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TransferComplete.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TransferComplete} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TransferComplete.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromSeat();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getToSeat();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 from_seat = 1;
 * @return {number}
 */
proto.TransferComplete.prototype.getFromSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TransferComplete} returns this
 */
proto.TransferComplete.prototype.setFromSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 to_seat = 2;
 * @return {number}
 */
proto.TransferComplete.prototype.getToSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TransferComplete} returns this
 */
proto.TransferComplete.prototype.setToSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ThrowawayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ThrowawayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ThrowawayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ThrowawayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ThrowawayRequest}
 */
proto.ThrowawayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ThrowawayRequest;
  return proto.ThrowawayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ThrowawayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ThrowawayRequest}
 */
proto.ThrowawayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ThrowawayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ThrowawayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ThrowawayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ThrowawayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ThrowawayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ThrowawayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ThrowawayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ThrowawayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    finished: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    cardRemoved: (f = msg.getCardRemoved()) && proto.Card.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ThrowawayResponse}
 */
proto.ThrowawayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ThrowawayResponse;
  return proto.ThrowawayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ThrowawayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ThrowawayResponse}
 */
proto.ThrowawayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFinished(value);
      break;
    case 2:
      var value = new proto.Card;
      reader.readMessage(value,proto.Card.deserializeBinaryFromReader);
      msg.setCardRemoved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ThrowawayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ThrowawayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ThrowawayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ThrowawayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFinished();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCardRemoved();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool finished = 1;
 * @return {boolean}
 */
proto.ThrowawayResponse.prototype.getFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ThrowawayResponse} returns this
 */
proto.ThrowawayResponse.prototype.setFinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Card card_removed = 2;
 * @return {?proto.Card}
 */
proto.ThrowawayResponse.prototype.getCardRemoved = function() {
  return /** @type{?proto.Card} */ (
    jspb.Message.getWrapperField(this, proto.Card, 2));
};


/**
 * @param {?proto.Card|undefined} value
 * @return {!proto.ThrowawayResponse} returns this
*/
proto.ThrowawayResponse.prototype.setCardRemoved = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ThrowawayResponse} returns this
 */
proto.ThrowawayResponse.prototype.clearCardRemoved = function() {
  return this.setCardRemoved(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ThrowawayResponse.prototype.hasCardRemoved = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrumpUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.TrumpUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrumpUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrumpUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    tricks: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shootNum: jspb.Message.getFieldWithDefault(msg, 2, 0),
    trump: jspb.Message.getFieldWithDefault(msg, 3, 0),
    seat: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrumpUpdate}
 */
proto.TrumpUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrumpUpdate;
  return proto.TrumpUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrumpUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrumpUpdate}
 */
proto.TrumpUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTricks(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setShootNum(value);
      break;
    case 3:
      var value = /** @type {!proto.Trump} */ (reader.readEnum());
      msg.setTrump(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrumpUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrumpUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrumpUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrumpUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTricks();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getShootNum();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTrump();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSeat();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 tricks = 1;
 * @return {number}
 */
proto.TrumpUpdate.prototype.getTricks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TrumpUpdate} returns this
 */
proto.TrumpUpdate.prototype.setTricks = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 shoot_num = 2;
 * @return {number}
 */
proto.TrumpUpdate.prototype.getShootNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TrumpUpdate} returns this
 */
proto.TrumpUpdate.prototype.setShootNum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Trump trump = 3;
 * @return {!proto.Trump}
 */
proto.TrumpUpdate.prototype.getTrump = function() {
  return /** @type {!proto.Trump} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Trump} value
 * @return {!proto.TrumpUpdate} returns this
 */
proto.TrumpUpdate.prototype.setTrump = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 seat = 4;
 * @return {number}
 */
proto.TrumpUpdate.prototype.getSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.TrumpUpdate} returns this
 */
proto.TrumpUpdate.prototype.setSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PlayCardRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.PlayCardRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PlayCardRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PlayCardRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seat: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeout: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PlayCardRequest}
 */
proto.PlayCardRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PlayCardRequest;
  return proto.PlayCardRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PlayCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PlayCardRequest}
 */
proto.PlayCardRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimeout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PlayCardRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PlayCardRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PlayCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PlayCardRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeat();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTimeout();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 seat = 1;
 * @return {number}
 */
proto.PlayCardRequest.prototype.getSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PlayCardRequest} returns this
 */
proto.PlayCardRequest.prototype.setSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 timeout = 2;
 * @return {number}
 */
proto.PlayCardRequest.prototype.getTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PlayCardRequest} returns this
 */
proto.PlayCardRequest.prototype.setTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateTimeout.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateTimeout.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateTimeout} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTimeout.toObject = function(includeInstance, msg) {
  var f, obj = {
    seat: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeout: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateTimeout}
 */
proto.UpdateTimeout.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateTimeout;
  return proto.UpdateTimeout.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateTimeout} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateTimeout}
 */
proto.UpdateTimeout.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimeout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateTimeout.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateTimeout.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateTimeout} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTimeout.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeat();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTimeout();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 seat = 1;
 * @return {number}
 */
proto.UpdateTimeout.prototype.getSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateTimeout} returns this
 */
proto.UpdateTimeout.prototype.setSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 timeout = 2;
 * @return {number}
 */
proto.UpdateTimeout.prototype.getTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateTimeout} returns this
 */
proto.UpdateTimeout.prototype.setTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PlayedCard.prototype.toObject = function(opt_includeInstance) {
  return proto.PlayedCard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PlayedCard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PlayedCard.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    card: (f = msg.getCard()) && proto.Card.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PlayedCard}
 */
proto.PlayedCard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PlayedCard;
  return proto.PlayedCard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PlayedCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PlayedCard}
 */
proto.PlayedCard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeat(value);
      break;
    case 3:
      var value = new proto.Card;
      reader.readMessage(value,proto.Card.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PlayedCard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PlayedCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PlayedCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PlayedCard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSeat();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Card.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 order = 1;
 * @return {number}
 */
proto.PlayedCard.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PlayedCard} returns this
 */
proto.PlayedCard.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 seat = 2;
 * @return {number}
 */
proto.PlayedCard.prototype.getSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PlayedCard} returns this
 */
proto.PlayedCard.prototype.setSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Card card = 3;
 * @return {?proto.Card}
 */
proto.PlayedCard.prototype.getCard = function() {
  return /** @type{?proto.Card} */ (
    jspb.Message.getWrapperField(this, proto.Card, 3));
};


/**
 * @param {?proto.Card|undefined} value
 * @return {!proto.PlayedCard} returns this
*/
proto.PlayedCard.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PlayedCard} returns this
 */
proto.PlayedCard.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PlayedCard.prototype.hasCard = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PlayedCards.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PlayedCards.prototype.toObject = function(opt_includeInstance) {
  return proto.PlayedCards.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PlayedCards} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PlayedCards.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.PlayedCard.toObject, includeInstance),
    winningSeat: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PlayedCards}
 */
proto.PlayedCards.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PlayedCards;
  return proto.PlayedCards.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PlayedCards} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PlayedCards}
 */
proto.PlayedCards.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.PlayedCard;
      reader.readMessage(value,proto.PlayedCard.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWinningSeat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PlayedCards.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PlayedCards.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PlayedCards} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PlayedCards.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.PlayedCard.serializeBinaryToWriter
    );
  }
  f = message.getWinningSeat();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated PlayedCard cards = 1;
 * @return {!Array<!proto.PlayedCard>}
 */
proto.PlayedCards.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.PlayedCard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PlayedCard, 1));
};


/**
 * @param {!Array<!proto.PlayedCard>} value
 * @return {!proto.PlayedCards} returns this
*/
proto.PlayedCards.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.PlayedCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PlayedCard}
 */
proto.PlayedCards.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.PlayedCard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PlayedCards} returns this
 */
proto.PlayedCards.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


/**
 * optional uint32 winning_seat = 2;
 * @return {number}
 */
proto.PlayedCards.prototype.getWinningSeat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PlayedCards} returns this
 */
proto.PlayedCards.prototype.setWinningSeat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Tricks.prototype.toObject = function(opt_includeInstance) {
  return proto.Tricks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Tricks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Tricks.toObject = function(includeInstance, msg) {
  var f, obj = {
    team1: jspb.Message.getFieldWithDefault(msg, 1, 0),
    team2: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tricksRemainingInHand: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Tricks}
 */
proto.Tricks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Tricks;
  return proto.Tricks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Tricks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Tricks}
 */
proto.Tricks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeam1(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeam2(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTricksRemainingInHand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Tricks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Tricks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Tricks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Tricks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeam1();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTeam2();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTricksRemainingInHand();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 team_1 = 1;
 * @return {number}
 */
proto.Tricks.prototype.getTeam1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Tricks} returns this
 */
proto.Tricks.prototype.setTeam1 = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 team_2 = 2;
 * @return {number}
 */
proto.Tricks.prototype.getTeam2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Tricks} returns this
 */
proto.Tricks.prototype.setTeam2 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 tricks_remaining_in_hand = 3;
 * @return {number}
 */
proto.Tricks.prototype.getTricksRemainingInHand = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Tricks} returns this
 */
proto.Tricks.prototype.setTricksRemainingInHand = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Scores.prototype.toObject = function(opt_includeInstance) {
  return proto.Scores.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Scores} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Scores.toObject = function(includeInstance, msg) {
  var f, obj = {
    team1: jspb.Message.getFieldWithDefault(msg, 1, 0),
    team2: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Scores}
 */
proto.Scores.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Scores;
  return proto.Scores.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Scores} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Scores}
 */
proto.Scores.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeam1(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeam2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Scores.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Scores.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Scores} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Scores.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeam1();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTeam2();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 team_1 = 1;
 * @return {number}
 */
proto.Scores.prototype.getTeam1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Scores} returns this
 */
proto.Scores.prototype.setTeam1 = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 team_2 = 2;
 * @return {number}
 */
proto.Scores.prototype.getTeam2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Scores} returns this
 */
proto.Scores.prototype.setTeam2 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EndGame.prototype.toObject = function(opt_includeInstance) {
  return proto.EndGame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EndGame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EndGame.toObject = function(includeInstance, msg) {
  var f, obj = {
    winningTeam: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EndGame}
 */
proto.EndGame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EndGame;
  return proto.EndGame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EndGame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EndGame}
 */
proto.EndGame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWinningTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EndGame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EndGame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EndGame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EndGame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWinningTeam();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 winning_team = 1;
 * @return {number}
 */
proto.EndGame.prototype.getWinningTeam = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.EndGame} returns this
 */
proto.EndGame.prototype.setWinningTeam = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LeaveGameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.LeaveGameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LeaveGameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LeaveGameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LeaveGameRequest}
 */
proto.LeaveGameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LeaveGameRequest;
  return proto.LeaveGameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LeaveGameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LeaveGameRequest}
 */
proto.LeaveGameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LeaveGameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LeaveGameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LeaveGameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LeaveGameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Notification.oneofGroups_ = [[2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21]];

/**
 * @enum {number}
 */
proto.Notification.NotificationCase = {
  NOTIFICATION_NOT_SET: 0,
  STATUS: 2,
  JOIN_RESPONSE: 3,
  SEAT_LIST: 4,
  SEAT_UPDATE: 5,
  START_GAME: 6,
  HAND: 7,
  BID_REQUEST: 8,
  BID: 9,
  BID_LIST: 10,
  TRANSFER_REQUEST: 11,
  TRANSFER: 12,
  TRANSFER_COMPLETE: 13,
  THROWAWAY_REQUEST: 14,
  TRUMP_UPDATE: 15,
  PLAY_CARD_REQUEST: 16,
  UPDATE_TIMEOUT: 17,
  PLAYED_CARDS: 18,
  TRICKS: 19,
  SCORES: 20,
  END_GAME: 21
};

/**
 * @return {proto.Notification.NotificationCase}
 */
proto.Notification.prototype.getNotificationCase = function() {
  return /** @type {proto.Notification.NotificationCase} */(jspb.Message.computeOneofCase(this, proto.Notification.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    sequence: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: (f = msg.getStatus()) && proto.StatusResponse.toObject(includeInstance, f),
    joinResponse: (f = msg.getJoinResponse()) && proto.JoinGameResponse.toObject(includeInstance, f),
    seatList: (f = msg.getSeatList()) && proto.SeatsList.toObject(includeInstance, f),
    seatUpdate: (f = msg.getSeatUpdate()) && proto.SeatDetails.toObject(includeInstance, f),
    startGame: (f = msg.getStartGame()) && proto.StartGame.toObject(includeInstance, f),
    hand: (f = msg.getHand()) && proto.Hand.toObject(includeInstance, f),
    bidRequest: (f = msg.getBidRequest()) && proto.BidRequest.toObject(includeInstance, f),
    bid: (f = msg.getBid()) && proto.Bid.toObject(includeInstance, f),
    bidList: (f = msg.getBidList()) && proto.BidList.toObject(includeInstance, f),
    transferRequest: (f = msg.getTransferRequest()) && proto.TransferRequest.toObject(includeInstance, f),
    transfer: (f = msg.getTransfer()) && proto.Transfer.toObject(includeInstance, f),
    transferComplete: (f = msg.getTransferComplete()) && proto.TransferComplete.toObject(includeInstance, f),
    throwawayRequest: (f = msg.getThrowawayRequest()) && proto.ThrowawayRequest.toObject(includeInstance, f),
    trumpUpdate: (f = msg.getTrumpUpdate()) && proto.TrumpUpdate.toObject(includeInstance, f),
    playCardRequest: (f = msg.getPlayCardRequest()) && proto.PlayCardRequest.toObject(includeInstance, f),
    updateTimeout: (f = msg.getUpdateTimeout()) && proto.UpdateTimeout.toObject(includeInstance, f),
    playedCards: (f = msg.getPlayedCards()) && proto.PlayedCards.toObject(includeInstance, f),
    tricks: (f = msg.getTricks()) && proto.Tricks.toObject(includeInstance, f),
    scores: (f = msg.getScores()) && proto.Scores.toObject(includeInstance, f),
    endGame: (f = msg.getEndGame()) && proto.EndGame.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Notification}
 */
proto.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Notification;
  return proto.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Notification}
 */
proto.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSequence(value);
      break;
    case 2:
      var value = new proto.StatusResponse;
      reader.readMessage(value,proto.StatusResponse.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 3:
      var value = new proto.JoinGameResponse;
      reader.readMessage(value,proto.JoinGameResponse.deserializeBinaryFromReader);
      msg.setJoinResponse(value);
      break;
    case 4:
      var value = new proto.SeatsList;
      reader.readMessage(value,proto.SeatsList.deserializeBinaryFromReader);
      msg.setSeatList(value);
      break;
    case 5:
      var value = new proto.SeatDetails;
      reader.readMessage(value,proto.SeatDetails.deserializeBinaryFromReader);
      msg.setSeatUpdate(value);
      break;
    case 6:
      var value = new proto.StartGame;
      reader.readMessage(value,proto.StartGame.deserializeBinaryFromReader);
      msg.setStartGame(value);
      break;
    case 7:
      var value = new proto.Hand;
      reader.readMessage(value,proto.Hand.deserializeBinaryFromReader);
      msg.setHand(value);
      break;
    case 8:
      var value = new proto.BidRequest;
      reader.readMessage(value,proto.BidRequest.deserializeBinaryFromReader);
      msg.setBidRequest(value);
      break;
    case 9:
      var value = new proto.Bid;
      reader.readMessage(value,proto.Bid.deserializeBinaryFromReader);
      msg.setBid(value);
      break;
    case 10:
      var value = new proto.BidList;
      reader.readMessage(value,proto.BidList.deserializeBinaryFromReader);
      msg.setBidList(value);
      break;
    case 11:
      var value = new proto.TransferRequest;
      reader.readMessage(value,proto.TransferRequest.deserializeBinaryFromReader);
      msg.setTransferRequest(value);
      break;
    case 12:
      var value = new proto.Transfer;
      reader.readMessage(value,proto.Transfer.deserializeBinaryFromReader);
      msg.setTransfer(value);
      break;
    case 13:
      var value = new proto.TransferComplete;
      reader.readMessage(value,proto.TransferComplete.deserializeBinaryFromReader);
      msg.setTransferComplete(value);
      break;
    case 14:
      var value = new proto.ThrowawayRequest;
      reader.readMessage(value,proto.ThrowawayRequest.deserializeBinaryFromReader);
      msg.setThrowawayRequest(value);
      break;
    case 15:
      var value = new proto.TrumpUpdate;
      reader.readMessage(value,proto.TrumpUpdate.deserializeBinaryFromReader);
      msg.setTrumpUpdate(value);
      break;
    case 16:
      var value = new proto.PlayCardRequest;
      reader.readMessage(value,proto.PlayCardRequest.deserializeBinaryFromReader);
      msg.setPlayCardRequest(value);
      break;
    case 17:
      var value = new proto.UpdateTimeout;
      reader.readMessage(value,proto.UpdateTimeout.deserializeBinaryFromReader);
      msg.setUpdateTimeout(value);
      break;
    case 18:
      var value = new proto.PlayedCards;
      reader.readMessage(value,proto.PlayedCards.deserializeBinaryFromReader);
      msg.setPlayedCards(value);
      break;
    case 19:
      var value = new proto.Tricks;
      reader.readMessage(value,proto.Tricks.deserializeBinaryFromReader);
      msg.setTricks(value);
      break;
    case 20:
      var value = new proto.Scores;
      reader.readMessage(value,proto.Scores.deserializeBinaryFromReader);
      msg.setScores(value);
      break;
    case 21:
      var value = new proto.EndGame;
      reader.readMessage(value,proto.EndGame.deserializeBinaryFromReader);
      msg.setEndGame(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSequence();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.StatusResponse.serializeBinaryToWriter
    );
  }
  f = message.getJoinResponse();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.JoinGameResponse.serializeBinaryToWriter
    );
  }
  f = message.getSeatList();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.SeatsList.serializeBinaryToWriter
    );
  }
  f = message.getSeatUpdate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.SeatDetails.serializeBinaryToWriter
    );
  }
  f = message.getStartGame();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.StartGame.serializeBinaryToWriter
    );
  }
  f = message.getHand();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.Hand.serializeBinaryToWriter
    );
  }
  f = message.getBidRequest();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.BidRequest.serializeBinaryToWriter
    );
  }
  f = message.getBid();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.Bid.serializeBinaryToWriter
    );
  }
  f = message.getBidList();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.BidList.serializeBinaryToWriter
    );
  }
  f = message.getTransferRequest();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.TransferRequest.serializeBinaryToWriter
    );
  }
  f = message.getTransfer();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.Transfer.serializeBinaryToWriter
    );
  }
  f = message.getTransferComplete();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.TransferComplete.serializeBinaryToWriter
    );
  }
  f = message.getThrowawayRequest();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.ThrowawayRequest.serializeBinaryToWriter
    );
  }
  f = message.getTrumpUpdate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.TrumpUpdate.serializeBinaryToWriter
    );
  }
  f = message.getPlayCardRequest();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.PlayCardRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTimeout();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.UpdateTimeout.serializeBinaryToWriter
    );
  }
  f = message.getPlayedCards();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.PlayedCards.serializeBinaryToWriter
    );
  }
  f = message.getTricks();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.Tricks.serializeBinaryToWriter
    );
  }
  f = message.getScores();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.Scores.serializeBinaryToWriter
    );
  }
  f = message.getEndGame();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.EndGame.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 sequence = 1;
 * @return {number}
 */
proto.Notification.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional StatusResponse status = 2;
 * @return {?proto.StatusResponse}
 */
proto.Notification.prototype.getStatus = function() {
  return /** @type{?proto.StatusResponse} */ (
    jspb.Message.getWrapperField(this, proto.StatusResponse, 2));
};


/**
 * @param {?proto.StatusResponse|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional JoinGameResponse join_response = 3;
 * @return {?proto.JoinGameResponse}
 */
proto.Notification.prototype.getJoinResponse = function() {
  return /** @type{?proto.JoinGameResponse} */ (
    jspb.Message.getWrapperField(this, proto.JoinGameResponse, 3));
};


/**
 * @param {?proto.JoinGameResponse|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setJoinResponse = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearJoinResponse = function() {
  return this.setJoinResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasJoinResponse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SeatsList seat_list = 4;
 * @return {?proto.SeatsList}
 */
proto.Notification.prototype.getSeatList = function() {
  return /** @type{?proto.SeatsList} */ (
    jspb.Message.getWrapperField(this, proto.SeatsList, 4));
};


/**
 * @param {?proto.SeatsList|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setSeatList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearSeatList = function() {
  return this.setSeatList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasSeatList = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SeatDetails seat_update = 5;
 * @return {?proto.SeatDetails}
 */
proto.Notification.prototype.getSeatUpdate = function() {
  return /** @type{?proto.SeatDetails} */ (
    jspb.Message.getWrapperField(this, proto.SeatDetails, 5));
};


/**
 * @param {?proto.SeatDetails|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setSeatUpdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearSeatUpdate = function() {
  return this.setSeatUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasSeatUpdate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional StartGame start_game = 6;
 * @return {?proto.StartGame}
 */
proto.Notification.prototype.getStartGame = function() {
  return /** @type{?proto.StartGame} */ (
    jspb.Message.getWrapperField(this, proto.StartGame, 6));
};


/**
 * @param {?proto.StartGame|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setStartGame = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearStartGame = function() {
  return this.setStartGame(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasStartGame = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Hand hand = 7;
 * @return {?proto.Hand}
 */
proto.Notification.prototype.getHand = function() {
  return /** @type{?proto.Hand} */ (
    jspb.Message.getWrapperField(this, proto.Hand, 7));
};


/**
 * @param {?proto.Hand|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setHand = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearHand = function() {
  return this.setHand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasHand = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional BidRequest bid_request = 8;
 * @return {?proto.BidRequest}
 */
proto.Notification.prototype.getBidRequest = function() {
  return /** @type{?proto.BidRequest} */ (
    jspb.Message.getWrapperField(this, proto.BidRequest, 8));
};


/**
 * @param {?proto.BidRequest|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setBidRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearBidRequest = function() {
  return this.setBidRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasBidRequest = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Bid bid = 9;
 * @return {?proto.Bid}
 */
proto.Notification.prototype.getBid = function() {
  return /** @type{?proto.Bid} */ (
    jspb.Message.getWrapperField(this, proto.Bid, 9));
};


/**
 * @param {?proto.Bid|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setBid = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearBid = function() {
  return this.setBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasBid = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional BidList bid_list = 10;
 * @return {?proto.BidList}
 */
proto.Notification.prototype.getBidList = function() {
  return /** @type{?proto.BidList} */ (
    jspb.Message.getWrapperField(this, proto.BidList, 10));
};


/**
 * @param {?proto.BidList|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setBidList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearBidList = function() {
  return this.setBidList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasBidList = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional TransferRequest transfer_request = 11;
 * @return {?proto.TransferRequest}
 */
proto.Notification.prototype.getTransferRequest = function() {
  return /** @type{?proto.TransferRequest} */ (
    jspb.Message.getWrapperField(this, proto.TransferRequest, 11));
};


/**
 * @param {?proto.TransferRequest|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setTransferRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearTransferRequest = function() {
  return this.setTransferRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasTransferRequest = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Transfer transfer = 12;
 * @return {?proto.Transfer}
 */
proto.Notification.prototype.getTransfer = function() {
  return /** @type{?proto.Transfer} */ (
    jspb.Message.getWrapperField(this, proto.Transfer, 12));
};


/**
 * @param {?proto.Transfer|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setTransfer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearTransfer = function() {
  return this.setTransfer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasTransfer = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional TransferComplete transfer_complete = 13;
 * @return {?proto.TransferComplete}
 */
proto.Notification.prototype.getTransferComplete = function() {
  return /** @type{?proto.TransferComplete} */ (
    jspb.Message.getWrapperField(this, proto.TransferComplete, 13));
};


/**
 * @param {?proto.TransferComplete|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setTransferComplete = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearTransferComplete = function() {
  return this.setTransferComplete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasTransferComplete = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional ThrowawayRequest throwaway_request = 14;
 * @return {?proto.ThrowawayRequest}
 */
proto.Notification.prototype.getThrowawayRequest = function() {
  return /** @type{?proto.ThrowawayRequest} */ (
    jspb.Message.getWrapperField(this, proto.ThrowawayRequest, 14));
};


/**
 * @param {?proto.ThrowawayRequest|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setThrowawayRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearThrowawayRequest = function() {
  return this.setThrowawayRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasThrowawayRequest = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional TrumpUpdate trump_update = 15;
 * @return {?proto.TrumpUpdate}
 */
proto.Notification.prototype.getTrumpUpdate = function() {
  return /** @type{?proto.TrumpUpdate} */ (
    jspb.Message.getWrapperField(this, proto.TrumpUpdate, 15));
};


/**
 * @param {?proto.TrumpUpdate|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setTrumpUpdate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearTrumpUpdate = function() {
  return this.setTrumpUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasTrumpUpdate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional PlayCardRequest play_card_request = 16;
 * @return {?proto.PlayCardRequest}
 */
proto.Notification.prototype.getPlayCardRequest = function() {
  return /** @type{?proto.PlayCardRequest} */ (
    jspb.Message.getWrapperField(this, proto.PlayCardRequest, 16));
};


/**
 * @param {?proto.PlayCardRequest|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setPlayCardRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearPlayCardRequest = function() {
  return this.setPlayCardRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasPlayCardRequest = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional UpdateTimeout update_timeout = 17;
 * @return {?proto.UpdateTimeout}
 */
proto.Notification.prototype.getUpdateTimeout = function() {
  return /** @type{?proto.UpdateTimeout} */ (
    jspb.Message.getWrapperField(this, proto.UpdateTimeout, 17));
};


/**
 * @param {?proto.UpdateTimeout|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setUpdateTimeout = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearUpdateTimeout = function() {
  return this.setUpdateTimeout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasUpdateTimeout = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional PlayedCards played_cards = 18;
 * @return {?proto.PlayedCards}
 */
proto.Notification.prototype.getPlayedCards = function() {
  return /** @type{?proto.PlayedCards} */ (
    jspb.Message.getWrapperField(this, proto.PlayedCards, 18));
};


/**
 * @param {?proto.PlayedCards|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setPlayedCards = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearPlayedCards = function() {
  return this.setPlayedCards(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasPlayedCards = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional Tricks tricks = 19;
 * @return {?proto.Tricks}
 */
proto.Notification.prototype.getTricks = function() {
  return /** @type{?proto.Tricks} */ (
    jspb.Message.getWrapperField(this, proto.Tricks, 19));
};


/**
 * @param {?proto.Tricks|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setTricks = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearTricks = function() {
  return this.setTricks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasTricks = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Scores scores = 20;
 * @return {?proto.Scores}
 */
proto.Notification.prototype.getScores = function() {
  return /** @type{?proto.Scores} */ (
    jspb.Message.getWrapperField(this, proto.Scores, 20));
};


/**
 * @param {?proto.Scores|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setScores = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearScores = function() {
  return this.setScores(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasScores = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional EndGame end_game = 21;
 * @return {?proto.EndGame}
 */
proto.Notification.prototype.getEndGame = function() {
  return /** @type{?proto.EndGame} */ (
    jspb.Message.getWrapperField(this, proto.EndGame, 21));
};


/**
 * @param {?proto.EndGame|undefined} value
 * @return {!proto.Notification} returns this
*/
proto.Notification.prototype.setEndGame = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.Notification.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearEndGame = function() {
  return this.setEndGame(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasEndGame = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * @enum {number}
 */
proto.Trump = {
  SPADES: 0,
  HEARTS: 1,
  DIAMONDS: 2,
  CLUBS: 3,
  LOW: 4,
  HIGH: 5
};

goog.object.extend(exports, proto);
